// LOGO
import logoHtml from '../styles/img/logos/logo-html.png'
import logoCss from '../styles/img/logos/logo-css.png'
import logoSass from '../styles/img/logos/logo-sass.png'
import logoJS from '../styles/img/logos/logo-js.png'
import logoTS from '../styles/img/logos/logo-ts.png'
import logoNode from '../styles/img/logos/logo-node.png'
import logoMongo from '../styles/img/logos/logo-mongo.png'
import logoReact from '../styles/img/logos/logo-react.png'
import logoAngular from '../styles/img/logos/logo-angular.png'

// BOOKI
import bookiCover from '../styles/img/cover/1Booki/bookiCover.webp'
import bookiDesk from '../styles/img/cover/1Booki/bookiDesk.webp'
import bookiTab from '../styles/img/cover/1Booki/bookiTab.webp'
import bookiMobile from '../styles/img/cover/1Booki/bookiMobile.webp'
import bookiColors from '../styles/img/cover/1Booki/bookiColors.webp'
import bookiIcons from '../styles/img/cover/1Booki/bookiIcons.webp'

// OHMYFOOD
import ohmyfoodCover from '../styles/img/cover/2Ohmyfood/ohmyfoodCover.webp'
import ohmyfoodDesk from '../styles/img/cover/2Ohmyfood/ohmyfoodDesk.webp'
import ohmyfoodTab from '../styles/img/cover/2Ohmyfood/ohmyfoodTab.webp'
import ohmyfoodMobile from '../styles/img/cover/2Ohmyfood/ohmyfoodMobile.webp'
import ohmyfoodColors from '../styles/img/cover/2Ohmyfood/ohmyfoodColors.webp'
import ohmyfoodIcons from '../styles/img/cover/2Ohmyfood/ohmyfoodIcons.webp'

// LAPANTHERE
import lapanthereCover from '../styles/img/cover/3LaPanthere/lapanthereCover.webp'
import lapanthereBefore from '../styles/img/cover/3LaPanthere/lapanthereBefore.webp'
import lapanthereAfter from '../styles/img/cover/3LaPanthere/lapanthereAfter.webp'

// KANAP
import kanapCover from '../styles/img/cover/4Kanap/kanapCover.webp'
import kanapEtap1 from '../styles/img/cover/4Kanap/kanapEtap1.webp'
import kanapEtap2 from '../styles/img/cover/4Kanap/kanapEtap2.webp'
import kanapEtap3 from '../styles/img/cover/4Kanap/kanapEtap3.webp'
import kanapEtap4 from '../styles/img/cover/4Kanap/kanapEtap4.webp'

// HOTTAKES
import hottakesCover from '../styles/img/cover/5Hottakes/hottakesCover.webp'
import hottakesEtap1 from '../styles/img/cover/5Hottakes/hottakesEtap1.webp'
import hottakesEtap2 from '../styles/img/cover/5Hottakes/hottakesEtap2.webp'

// GROUPOMANIA
import groupoCover from '../styles/img/cover/6Groupomania/groupoCover.webp'
import groupoEtap1 from '../styles/img/cover/6Groupomania/groupoEtap1.webp'
import groupoEtap2 from '../styles/img/cover/6Groupomania/groupoEtap2.webp'
import groupoEtap3 from '../styles/img/cover/6Groupomania/groupoEtap3.webp'
import groupoEtap4 from '../styles/img/cover/6Groupomania/groupoEtap4.webp'
import groupoEtap5 from '../styles/img/cover/6Groupomania/groupoEtap5.webp'
import groupoEtap6 from '../styles/img/cover/6Groupomania/groupoEtap6.webp'
import groupoColors from '../styles/img/cover/6Groupomania/groupoColors.webp'
import groupoIcons from '../styles/img/cover/6Groupomania/groupoIcons.webp'

// 7 KASA
import kasaCover from '../styles/img/cover/7Kasa/kasaCover.webp'
import kasaEtap1 from '../styles/img/cover/7Kasa/kasaEtap1.webp'
import kasaEtap2 from '../styles/img/cover/7Kasa/kasaEtap2.webp'
import kasaEtap3 from '../styles/img/cover/7Kasa/kasaEtap3.webp'
import kasaEtap4 from '../styles/img/cover/7Kasa/kasaEtap4.webp'
import kasaEtap5 from '../styles/img/cover/7Kasa/kasaEtap5.webp'
import kasaEtap6 from '../styles/img/cover/7Kasa/kasaEtap6.webp'
import kasaColors from '../styles/img/cover/7Kasa/kasaColors.webp'

// 8 REFONTE HOTTAKES
import refonteHotTakesCover from '../styles/img/cover/8RefonteHottakes/refonteHotTakesCover.webp'
import refonteHotTakesEtap1 from '../styles/img/cover/8RefonteHottakes/refonteHotTakesEtap1.webp'
import refonteHotTakesEtap2 from '../styles/img/cover/8RefonteHottakes/refonteHotTakesEtap2.webp'
import refonteHotTakesEtap3 from '../styles/img/cover/8RefonteHottakes/refonteHotTakesEtap3.webp'
import refonteHotTakesEtap4 from '../styles/img/cover/8RefonteHottakes/refonteHotTakesEtap4.webp'
import refonteHotTakesEtap5 from '../styles/img/cover/8RefonteHottakes/refonteHotTakesEtap5.webp'
import refonteHotTakesEtap6 from '../styles/img/cover/8RefonteHottakes/refonteHotTakesEtap6.webp'

// 9 WEDDING SITE
import weddingSiteCover from '../styles/img/cover/9WeddingSite/weddingSiteCover.webp'
import weddingSiteLogin1 from '../styles/img/cover/9WeddingSite/weddingSiteLogin1.webp'
import weddingSiteLogin2 from '../styles/img/cover/9WeddingSite/weddingSiteLogin2.webp'
import weddingSiteHome1 from '../styles/img/cover/9WeddingSite/weddingSiteHome1.webp'
import weddingSiteHome2 from '../styles/img/cover/9WeddingSite/weddingSiteHome2.webp'
import weddingSiteBlog1 from '../styles/img/cover/9WeddingSite/weddingSiteBlog1.webp'
import weddingSiteBlog2 from '../styles/img/cover/9WeddingSite/weddingSiteBlog2.webp'
import weddingSiteBlog3 from '../styles/img/cover/9WeddingSite/weddingSiteBlog3.webp'
import weddingSiteBlog4 from '../styles/img/cover/9WeddingSite/weddingSiteBlog4.webp'
import weddingSiteConfirm1 from '../styles/img/cover/9WeddingSite/weddingSiteConfirm1.webp'
import weddingSiteConfirm2 from '../styles/img/cover/9WeddingSite/weddingSiteConfirm2.webp'
import weddingSiteContact1 from '../styles/img/cover/9WeddingSite/weddingSiteContact1.webp'
import weddingSiteContact2 from '../styles/img/cover/9WeddingSite/weddingSiteContact2.webp'

// GROUPOMANIA ANGULAR
import groupoAngularCover from '../styles/img/cover/10GroupoAngular/groupoAngularCover.webp'
import groupoAngularEtap1 from '../styles/img/cover/10GroupoAngular/groupoAngularEtap1.webp'
import groupoAngularEtap2 from '../styles/img/cover/10GroupoAngular/groupoAngularEtap2.webp'
import groupoAngularEtap3 from '../styles/img/cover/10GroupoAngular/groupoAngularEtap3.webp'
import groupoAngularEtap4 from '../styles/img/cover/10GroupoAngular/groupoAngularEtap4.webp'
import groupoAngularEtap5 from '../styles/img/cover/10GroupoAngular/groupoAngularEtap5.webp'
import groupoAngularEtap6 from '../styles/img/cover/10GroupoAngular/groupoAngularEtap6.webp'

export const projectsList = [
    {
        id: '10',
        name: 'Groupomania - Angular',
        cover: groupoAngularCover,
        resume: 'Création d\'un réseau social d\'entreprise avec Angular',
        context: 'Groupomania est une entreprise ayant un ralentissement de sa productivité attribué à une baisse de la motivation et de l\'implication des employés.',
        context1: ' Elle souhaite donc créer un réseau social d\'entreprise afin d\'améliorer la relation entre ces derniers.',
        objectifs: 'Créer une partie front-end avec un framework, ici Angular.',
        objectifs1: 'Également, une API sécurisée permettant aux utilisateurs de se créer un compte, se connecter et se déconnecter. Ainsi que créer un post, modifier ou supprimer leur post, et liker tous les posts.',
        objectifs2: ' J\'ai ajouté en plus une page où les utilisateurs peuvent modifier leurs informations.',
        languages: [logoHtml, logoCss, logoSass, logoTS, logoNode, logoMongo, logoAngular],
        picturesEtap: [groupoAngularEtap1, groupoAngularEtap2, groupoAngularEtap3, groupoAngularEtap4, groupoAngularEtap5, groupoAngularEtap6],
        gitLinkBack: 'https://github.com/CamilleLinan/Projet-7-Groupomania-Back',
        gitLinkFront: 'https://github.com/CamilleLinan/Groupomania_Angular'
    },
    {
        id: '09',
        name: 'Site de Mariage',
        cover: weddingSiteCover,
        resume: 'Création d\'une application de gestion de mariage avec blog',
        context: 'Marie et Paul se marient dans quelques mois. Les futurs mariés voudraient un site web pour annoncer leur date et lieu de mariage, ainsi qu\'un blog sur lequel ils pourraient raconter leur histoire à leurs proches.',
        context1: ' Ils souhaiteraient en plus une section pour gérer la liste des invités et que les invités eux-même puissent confirmer leur présence.',
        objectifs: 'Mon objectif de base était de créer une page d\'accueil de site web de mariage comme l\'on peut voir sur Mariages.net.',
        objectifs1: 'J\'ai voulu aller plus loin en ajoutant des fonctionnalités comme une section "blog" que seul l\'administrateur du site peut gérer, une section "Liste des invités"/"Confirmer sa présence" (suivant s\'il on est administrateur ou non), ainsi qu\'une section "Contact".',
        objectifs2: 'Plusieurs fonctionnalités sont encore à améliorer, ceci est un projet personnel en cours de progression.',
        languages: [logoHtml, logoCss, logoSass, logoJS, logoNode, logoMongo, logoReact],
        picturesLogin: [weddingSiteLogin1, weddingSiteLogin2],
        picturesHome: [weddingSiteHome1, weddingSiteHome2],
        picturesBlog: [weddingSiteBlog1, weddingSiteBlog2, weddingSiteBlog3, weddingSiteBlog4],
        picturesConfirm: [weddingSiteConfirm1, weddingSiteConfirm2],
        picturesContact: [weddingSiteContact1, weddingSiteContact2],
        gitLinkBack: 'https://github.com/CamilleLinan/Site-Mariage-Back',
        gitLinkFront: 'https://github.com/CamilleLinan/Site-Mariage-Front'
    },
    {
        id: '08',
        name: 'Refonte Hot Takes',
        cover: refonteHotTakesCover,
        resume: 'Refonte des parties Front et Back de l\'application Hot Takes',
        context: 'Les sauces piquantes sont de plus en plus populaires, c\'est pourquoi la marque de condiments à base de piment Piiquante souhaite développer une application web de critique des sauces piquantes appelée "Hot Takes".',
        objectifs: 'Construire une API sécurisée avec ses models, controllers et routes. Les utilisateurs de l\'application doivent pouvoir créer de nouvelles sauces, modifier ou supprimer leur sauce, ainsi que liker ou disliker toutes les sauces créées.',
        objectifs1: 'Le visuel de base de l\'application étant plutôt austère, j\'ai choisi de retravailler toute la partie front-end avec React. J\'ai également retravaillé la partie back-end afin que le tout soit plus ergonomique et sécurisé.',
        languages: [logoHtml, logoCss, logoSass, logoJS, logoNode, logoMongo, logoReact],
        picturesEtap: [refonteHotTakesEtap1, refonteHotTakesEtap2, refonteHotTakesEtap3, refonteHotTakesEtap4, refonteHotTakesEtap5, refonteHotTakesEtap6],
        gitLinkBack: 'https://github.com/CamilleLinan/Refonte-HotTakes-Back',
        gitLinkFront: 'https://github.com/CamilleLinan/Refonte-HotTakes-Front'
    },
    {
        id: '07',
        name: 'Kasa',
        cover: kasaCover,
        resume: 'Création d\'une application web de location immobilière avec React',
        context: 'Kasa est dans le métier de la location d’appartements entre particuliers depuis près de 10 ans.',
        context1: 'L\'entreprise souhaite faire une refonte totale de son site web.',
        objectifs: 'Développer l\'ensemble de l\'application et ses composants avec React, en suivant les maquettes Figma fournies.',
        objectifs1: ' Des composants réutilisables doivent être créés pour les onglets ouvrants et le carrousel de photo.',
        languages: [logoHtml, logoCss, logoSass, logoJS, logoReact],
        picturesEtap: [kasaEtap1, kasaEtap2, kasaEtap3, kasaEtap4, kasaEtap5, kasaEtap6],
        colors: kasaColors,
        gitLink: 'https://github.com/CamilleLinan/Projet-8-Kasa',
        webSite: 'https://projet-8-kasa-brnf30w8b-camillelinan.vercel.app/'
    },
    {
        id: '06',
        name: 'Groupomania',
        cover: groupoCover,
        resume: 'Création d\'un réseau social d\'entreprise avec React.js',
        context: 'Groupomania est une entreprise ayant un ralentissement de sa productivité attribué à une baisse de la motivation et de l\'implication des employés.',
        context1: ' Elle souhaite donc créer un réseau social d\'entreprise afin d\'améliorer la relation entre ces derniers.',
        objectifs: 'Créer une partie front-end avec un framework JavaScript, ici React.',
        objectifs1: 'Également, une API sécurisée permettant aux utilisateurs de se créer un compte, se connecter et se déconnecter. Ainsi que créer un post, modifier ou supprimer leur post, et liker tous les posts.',
        objectifs2: ' J\'ai ajouté en plus une page où les utilisateurs peuvent modifier leurs informations.',
        languages: [logoHtml, logoCss, logoSass, logoJS, logoNode, logoMongo, logoReact],
        picturesEtap: [groupoEtap1, groupoEtap2, groupoEtap3, groupoEtap4, groupoEtap5, groupoEtap6],
        colors: groupoColors,
        icons: groupoIcons,
        gitLinkBack: 'https://github.com/CamilleLinan/Projet-7-Groupomania-Back',
        gitLinkFront: 'https://github.com/CamilleLinan/Projet-7-Groupomania-Front'  
    },
    {
        id: '05',
        name: 'Hot Takes',
        cover: hottakesCover,
        resume: 'Création d\'un serveur et d\'une API avec Node, Express et MongoDB',
        context: 'Les sauces piquantes sont de plus en plus populaires, c\'est pourquoi la marque de condiments à base de piment Piiquante souhaite développer une application web de critique des sauces piquantes appelée "Hot Takes".',
        objectifs: 'Construire une API sécurisée avec ses models, controllers et routes.',
        objectifs1: 'Les utilisateurs de l\'application doivent pouvoir créer de nouvelles sauces, modifier ou supprimer leur sauce, ainsi que liker ou disliker toutes les sauces créées.',
        languages: [logoNode, logoMongo],
        picturesEtap: [hottakesEtap1, hottakesEtap2],
        gitLink: 'https://github.com/CamilleLinan/Projet-6-Piiquante-Back'
    },
    {
        id: '04',
        name: 'Kanap',
        cover: kanapCover,
        resume: 'Intégration d\'une API en JavaScript',
        context: 'Kanap est une marque de canapés qui vend ses produits depuis sa boutique exclusivement.',
        context1: 'Aujourd’hui, celle-ci souhaiterait avoir une plateforme de e-commerce en plus de sa boutique physique pour vendre ses produits sur Internet.',
        objectifs: 'Unifier les travaux déjà réalisés par les développeurs front-end et back-end, en intégrant dynamiquement les éléments de l\'API dans les différentes pages web avec JavaScript.',
        objectifs1: ' L\'utilisateur doit pouvoir visualiser l\'ensemble des produits disponibles, ajouter un ou plusieurs article dans un panier, modifier ou supprimer son panier et enfin, passer commande.',
        languages: [logoHtml, logoJS],
        picturesEtap: [kanapEtap1, kanapEtap2, kanapEtap3, kanapEtap4],
        gitLink: 'https://github.com/CamilleLinan/Projet-5-Kanap'
    },
    {
        id: '03',
        name: 'La Panthere',
        cover: lapanthereCover,
        resume: 'Refonte de site web et SEO',
        context: 'La Panthère est une grande agence de web design basée à Lyon.',
        context1: 'L\'activité de l\'entreprise est en perte de vitesse et son site web n\'apparaît pas en première page des moteurs de recherche.',
        objectifs: 'Analyser l\'état actuel de SEO du site fourni et améliorer les points les plus importants concernant notamment la vitesse, la taille et l\'accessibilité du site.',
        objectifs1: 'Puis créer un rapport d\'optimisations pour comparer les résultats avant/après fournis par différents analyseurs de qualité et performance web.',
        languages: [logoHtml, logoCss],
        imgBefore: lapanthereBefore,
        imgAfter: lapanthereAfter,
        gitLink: 'https://github.com/CamilleLinan/Projet-4-La-Panthere',
        webSite: 'https://camillelinan.github.io/Projet-4-La-Panthere/'
    },
    {
        id: '02',
        name: 'OhMyFood',
        cover: ohmyfoodCover,
        resume: 'Intégration de maquette en HTML et animation avec SASS',
        context: 'Ohmyfood! est une jeune startup voulant s\'imposer sur le marché de la restauration.',
        context1: 'En plus des systèmes classiques de réservation, les clients pourront composer le menu de leur repas pour que les plats soient prêts à leur arrivée.',
        objectifs: 'Intégrer la maquette créée par le designer UI en mobilefirst en respectant les contraintes graphiques et d\'animations, sans utiliser de JavaScript.',
        objectifs1: 'Mise en page libre pour les versions desktop et tablette.',
        languages: [logoHtml, logoCss, logoSass],
        picturesPres: [ohmyfoodDesk, ohmyfoodTab, ohmyfoodMobile],
        colors: ohmyfoodColors,
        icons: ohmyfoodIcons,
        gitLink: 'https://github.com/CamilleLinan/Projet-3-Ohmyfood',
        webSite: 'https://camillelinan.github.io/Projet-3-Ohmyfood/'
    },
    {
        id: '01',
        name: 'Booki',
        cover: bookiCover,
        resume: 'Intégration de maquette en HTML et CSS',
        context: 'Booki est une petite entreprise proposant un outil de planification de vacances.',
        context1: 'Le site permet aux usagers de trouver des hébergements et des activités dans la ville de leur choix.',
        objectifs: 'Créer un prototype de site en intégrant la maquette conçue par le designer UI, en HTML et CSS. Le site doit bien sûr être responsive.',
        languages: [logoHtml, logoCss],
        picturesPres: [bookiDesk, bookiTab, bookiMobile],
        colors: bookiColors,
        icons: bookiIcons,
        gitLink: 'https://github.com/CamilleLinan/Projet-2-Booki',
        webSite: 'https://camillelinan.github.io/Projet-2-Booki/',
    }
]