import React from "react"
import IndexRoutes from "./components/Routes/IndexRoutes";

const App = () => {
  return (
    <>
      <IndexRoutes />
    </>
  );
}

export default App;
